import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from '@/components/ui/Toast';
import { useToast } from '@/hooks/useToast';
export function Toaster() {
  const {
    toasts
  } = useToast();
  return <ToastProvider data-sentry-element="ToastProvider" data-sentry-component="Toaster" data-sentry-source-file="Toaster.tsx">
      {toasts.map(({
      id,
      title,
      description,
      action,
      ...props
    }) => <Toast key={id} {...props}>
          <div className="grid gap-1">
            {title && <ToastTitle>{title}</ToastTitle>}
            {description && <ToastDescription>{description}</ToastDescription>}
          </div>
          {action}
          <ToastClose />
        </Toast>)}
      <ToastViewport data-sentry-element="ToastViewport" data-sentry-source-file="Toaster.tsx" />
    </ToastProvider>;
}