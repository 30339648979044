import React, { createContext, useContext, useState } from 'react';
type ApiSource = 'athena' | 'google';
interface ApiContextType {
  apiSource: ApiSource;
  setApiSource: (source: ApiSource) => void;
}
const ApiContext = createContext<ApiContextType | undefined>(undefined);

// TEMPORARY: This is a temporary context to allow for the switch between Athena and Google APIs.
export function ApiProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [apiSource, setApiSource] = useState<ApiSource>('athena');
  return <ApiContext.Provider value={{
    apiSource,
    setApiSource
  }} data-sentry-element="unknown" data-sentry-component="ApiProvider" data-sentry-source-file="ApiContext.tsx">{children}</ApiContext.Provider>;
}
export function useApi() {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
}